import React, { useState } from 'react';

const FetchButton: React.FC = () => {
  const [data, setData] = useState<string | null>(null);

  const fetchData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/api`);
      const result = await response.json();
      setData(result.message); 
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  return (
    <div>
      <button onClick={fetchData}>Fetch Backend Data</button>
      {data && <p>{data}</p>}
    </div>
  );
};

export default FetchButton;
 