import React from 'react';
import FetchButton from './components/FetchButton';
import { Button } from "./components/ui/Button";
import { ThemeToggle } from "./components/ui/ThemeToggle"; 

function App() {
  return (
    <div className="App p-6">
      <h1 className="text-4xl font-bold">Frontend App</h1>
      <h2 className="text-3xl">Subheading H2</h2>
      <h3 className="text-2xl">Subheading H3</h3>
      <p className="text-lg">This is a paragraph demonstrating typography.</p>
      <blockquote className="border-l-4 pl-4 italic">This is a blockquote.</blockquote>
      <ul className="list-disc pl-5">
        <li>First item</li>
        <li>Second item</li>
      </ul>
      <FetchButton />
      <Button variant="secondary">Secondary Button</Button>
      <ThemeToggle />
    </div>
  );
}

export default App;
